import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import HistoryV1 from './section-components/history';
import WhyChooseUs from './section-components/why-choose-us';

import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const History = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Disclaimer" subheader="Disclaimer"/>
         <br/>
        <br/>


        <WhyChooseUs />
        <br/>
        <br/>
        <br/>



        <Footer />
    </div>
}

export default History

