import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
								<li><a href="https://www.facebook.com/Moovmynt-101542262090927" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="https://x.com/i/flow/login?redirect_after_login=%2FMoovmynt" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="https://www.youtube.com/channel/UCjiAgIeiBd6oDX0GzF-6ZBQ" title="Youtube"><i className="fab fa-youtube" /></a></li>
						<li><a href="https://www.instagram.com/moovmynt/" title="Instagram"><i className="fab fa-instagram" /></a></li>
			</ul>
		</div>
        }
}

export default Social