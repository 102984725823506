import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class FaqV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__faq-area mb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className="ltn__faq-inner ltn__faq-inner-2">
                            <div id="accordion_2">
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-1" aria-expanded="false">
                                        What is MoovAI?
                                    </h6>
                                    <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>MoovAI is our platform's main tool for analyzing and understanding real
                                                estate opportunities. It provides detailed insights on property value,
                                                investment potential, and expected returns. MoovAI makes it easier for
                                                homebuyers, realtors, and investors to make informed decisions by
                                                offering clear, actionable data on pre-sale properties.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-2" aria-expanded="false">
                                        What does MoovAI do?
                                    </h6>
                                    <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>MoovAI simplifies the home buying and investment process by providing
                                                in-depth insights into properties. It helps you compare homes, calculate
                                                potential returns on investment, and even manage client information if
                                                you're a realtor. Think of it as your personal assistant, always
                                                up-to-date with the latest property data and trends.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-3" aria-expanded="false">
                                        What is Moovmynt?
                                    </h6>
                                    <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Moovmynt is an innovative real estate platform that provides advanced
                                                tools to simplify the pre-sale property market. Whether you're buying a
                                                home or investing in real estate, Moovmynt offers insights and data to
                                                help you make the best decisions. With the help of MoovAI, we focus on
                                                making the entire process smoother and more accessible.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-4" aria-expanded="false">
                                        What is the website about?
                                    </h6>
                                    <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>At Moovmynt, we're changing the way people approach real estate. Our
                                                website simplifies the pre-sale property market, making it easier for
                                                homebuyers, realtors, and investors to make smart, informed decisions.
                                                Powered by MoovAI, our advanced AI tool, we provide clear, actionable
                                                insights, ROI calculations, and a comprehensive Pre-Sale Master List.
                                                Whether you're buying your first home, looking to invest, or navigating
                                                client management as a realtor, we're here to help with the latest tools
                                                and data to guide your journey.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-5" aria-expanded="false">
                                        Who created the website?
                                    </h6>
                                    <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>The Moovmynt team built this website with a clear purpose in mind: to
                                                empower real estate buyers and investors with the tools they need to
                                                navigate the pre-sale property market confidently. We are passionate
                                                about making the home buying and investing process easier through
                                                advanced technology, and we've designed the site to be user-friendly,
                                                informative, and packed with valuable insights.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-6" aria-expanded="false">
                                        Who runs the website?
                                    </h6>
                                    <div id="faq-item-2-6" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Moovmynt is run by a team of forward-thinking real estate and technology
                                                experts. Our focus is on providing real-time data, AI-driven analysis,
                                                and tools that simplify complex real estate decisions. We're dedicated
                                                to helping you succeed, whether you're a buyer, a realtor, or an
                                                investor.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-7" aria-expanded="false">
                                        How is Moovmynt different from other real estate platforms?
                                    </h6>
                                    <div id="faq-item-2-7" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Moovmynt goes beyond just listing properties. With MoovAI, you get access
                                                to advanced analysis, detailed insights on pricing, deposit structures,
                                                and even commission information. We're also preparing to integrate
                                                blockchain technology and real estate tokenization in the future.
                                                Moovmynt provides the confidence you need in every decision, whether
                                                you're buying your dream home or investing in property.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-8" aria-expanded="false">
                                        What are pre-sale properties?
                                    </h6>
                                    <div id="faq-item-2-8" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Pre-sale properties are homes that are sold before construction is
                                                completed. These properties often come with advantages like lower prices
                                                and a wide range of customization options. They present fantastic
                                                opportunities for buyers and investors who want to get in early on a
                                                project.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-9" aria-expanded="false">
                                        How does Moovmynt help with pre-sale properties?
                                    </h6>
                                    <div id="faq-item-2-9" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Moovmynt gives you access to a wealth of information through our Pre-Sale
                                                Master List. You'll find details on upcoming developments, pricing,
                                                floor plans, and deposit structures, making it easier to evaluate the
                                                best options before committing to a purchase.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-10" aria-expanded="false">
                                        What is the Pre-Sale Master List?
                                    </h6>
                                    <div id="faq-item-2-10" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>The Pre-Sale Master List is your go-to resource for detailed information
                                                on new property developments. Stay ahead of the market with real-time
                                                data on pricing, floor plans, and deposit structures. Whether you're a
                                                homebuyer or a realtor, the Pre-Sale Master List gives you the insights
                                                needed to make smart decisions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-11" aria-expanded="false">
                                        Can Moovmynt help with financial planning for buying pre-sale properties?
                                    </h6>
                                    <div id="faq-item-2-11" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Absolutely! Our Deposit Structure Metrics give you a clear picture of the financial commitments required for different properties. With MoovAI’s insights, you can refine your investment strategy and stay prepared at every step. Please note that this information is for informational purposes only and does not constitute professional or financial advice.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-12" aria-expanded="false">
                                        How does Moovmynt track commissions and bonuses?
                                    </h6>
                                    <div id="faq-item-2-12" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Our platform provides clear insights into commission structures and bonus schedules, which is particularly useful for realtors and investors. MoovAI ensures transparency, so you'll know exactly when and how commissions are paid out, making financial management a breeze.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-13" aria-expanded="false">
                                        What is blockchain verification, and how will MoovAI use it?
                                    </h6>
                                    <div id="faq-item-2-13" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>In the near future, MoovAI will leverage blockchain technology to verify property data and ownership records. Blockchain makes these records tamper-proof and transparent, adding an extra layer of trust and security to your real estate transactions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-14" aria-expanded="false">
                                        What is real estate tokenization?
                                    </h6>
                                    <div id="faq-item-2-14" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Real estate tokenization is an exciting new way to invest in properties. It allows for fractional ownership, so you can invest in a portion of a property instead of the entire thing. This feature will make it easier for people to diversify their investments and access high-value properties backed by blockchain technology..</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-15" aria-expanded="false">
                                        What are DeFi and dApps in real estate?
                                    </h6>
                                    <div id="faq-item-2-15" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Decentralized Finance (DeFi) and Decentralized Applications (dApps) are future features that will allow you to earn, lend, and trade real estate assets in a peer-to-peer system. These solutions will make real estate more accessible and give you more control over your investments—all within a secure blockchain ecosystem.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-16" aria-expanded="false">
                                        When will blockchain and tokenization features be available?
                                    </h6>
                                    <div id="faq-item-2-16" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Moovmynt is always evolving. While we're focused on delivering the best AI-driven tools right now, blockchain and tokenization features are in development. Stay tuned for these exciting updates as we bring the future of real estate to you!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-17" aria-expanded="false">
                                        How can I contact the website owner?
                                    </h6>
                                    <div id="faq-item-2-17" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>We're always here to help! If you have any questions or need assistance, please contact us at info@moovmynt.com or fill out the contact form on our website. We'll respond as quickly as possible because your success is our priority.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-18" aria-expanded="false">
                                        How can I contact the website owner?
                                    </h6>
                                    <div id="faq-item-2-18" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>We're always here to help! If you have any questions or need assistance, please contact us at info@moovmynt.com or fill out the contact form on our website. We'll respond as quickly as possible because your success is our priority.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-19" aria-expanded="false">
                                        Can I use the information on the website for my own purposes?
                                    </h6>
                                    <div id="faq-item-2-19" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>The content on Moovmynt is designed to be a helpful resource for anyone looking to better understand the pre-sale property market and investment strategies. While we encourage you to use the information for educational purposes, please review our terms of use for guidelines on sharing and using our content. If you have specific questions, feel free to contact us!.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-20" aria-expanded="false">
                                        What are the copyright restrictions on the information on the website?
                                    </h6>
                                    <div id="faq-item-2-20" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>All content on the Moovmynt website is protected by copyright and belongs to us or the respective content owners. We kindly ask that you don't use the material without permission. For more details, check out our terms of use, and if you're unsure, just reach out—we're happy to clarify any concerns.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-21" aria-expanded="false">
                                        How often is the information on the website updated?
                                    </h6>
                                    <div id="faq-item-2-21" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>We're committed to keeping you in the loop with the latest real estate trends, tools, and market data. You can expect regular updates to our content, so bookmark our site or subscribe for updates. We're here to ensure you always have the most accurate and up-to-date information!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-22" aria-expanded="false">
                                        Is the website secure?
                                    </h6>
                                    <div id="faq-item-2-22" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Your security is our priority. The Moovmynt website is fully secure, but we're all ears. If you ever have concerns or questions about our site's security, please contact us at info@moovmynt.com, and we'll gladly address any feedback.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-23" aria-expanded="false">
                                        Is the information on the website reliable?
                                    </h6>
                                    <div id="faq-item-2-23" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>At Moovmynt, we take great care to ensure that all the information provided is accurate, up-to-date, and reliable. We aim to give you the best possible tools to make informed real estate decisions and stand by the quality of the data we share. You can trust that we provide the information you need to make confident, data-driven choices.</p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="need-support text-center mt-100">
                                <h2>Still need help? Reach out to support 24/7:</h2>
                                <div className="btn-wrapper mb-30 go-top">
                                    <Link to="/contact" className="theme-btn-1 btn">Contact Us</Link>
                                </div>
                                <h3><i className="fas fa-phone"/> +1 604-283-7610</h3>
                            </div>
                        </div>
                    </div>
                     <div className="col-lg-2"></div>

                </div>
            </div>
        </div>

    }
}

export default FaqV1