import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-120 pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
			          <img src={publicUrl+"assets/img/others/7.png"} alt="About Us Image" />
			          <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
			            <div className="ltn__video-img ltn__animation-pulse1">
			              <img src={publicUrl+"assets/img/others/8.png" } alt="video popup bg image" />

			              <a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/n1F_6-z91uw" data-rel="lightcase:myCollection">
			                <i className="fa fa-play" />
			              </a>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-20">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">What We Do?</h6>
			            <h1 className="section-title">Empower Smarter Property Decisions<span>.</span></h1>
						  <p>At Moovmynt, we specialize in simplifying the pre-sale property market through advanced tools and insights. Our platform, powered by <b>MoovAI</b>, provides realtors, homebuyers, and investors with the resources needed to navigate complex decisions with confidence. With our Pre-Sale Master List and AI-driven analysis, you’ll have access to comprehensive property data, helping you stay ahead of the market and make data-driven choices every step of the way.</p>
			          </div>
			          <ul className="ltn__list-item-half clearfix">
			            <li>
			              <i className="flaticon-home-2" />
			              Pre-Sale Master List
			            </li>
			            <li>
			              <i className="flaticon-mountain" />
			              Deposit Structure Metrics
			            </li>
			            <li>
			              <i className="flaticon-heart" />
			              Commissions & Bonuses
			            </li>

			          </ul>
			          <div className="ltn__callout bg-overlay-theme-05  mt-30">
						  <p>"Stay Ahead with <b>MoovAI’s</b> Advanced Pre-Sale Tools.<br />
			              Gain a competitive edge in the pre-sale market with Moovmynt. Powered by <b>MoovAI</b>, our platform combines a comprehensive property database with advanced tools for smart, data-driven decisions." </p>
			          </div>
			          <div className="btn-wrapper animated go-top">
			            <Link to="/service" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV1