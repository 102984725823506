import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map mb-120">
			<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d18091.347458644366!2d-123.012705!3d49.260249!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867727c3b228c7%3A0x275ec95d08681901!2s4170%20Still%20Creek%20Dr%20Ste%20200%2C%20Burnaby%2C%20BC%20V5C%206C6%2C%20Canada!5e1!3m2!1sen!2sus!4v1728867482688!5m2!1sen!2sus" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
		</div>
        }
}

export default Map