import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class WhyChooseUs extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <>
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center">
						<div className="ltn__feature-icon">
							<span><i className="flaticon-house-4" /></span>
						</div>

						<h1 className="section-title">Disclaimer</h1>
					</div>
					</div>
				</div>
				<div className="row justify-content-left" >
					<div className="col-xl-12 col-sm-6 col-12">


						<div className="ltn__feature-info">
						<p>The information provided on this platform is intended for educational purposes only and does not constitute financial or professional advice. Our platform features pre-construction home data sourced directly from developers, as well as through their official websites, social media, and other reputable sources. While we strive for accuracy and routinely engage with developers to bring you the latest updates, please be advised that the data may be subject to change as projects evolve.</p>
						<p>Certain features, pricing, availability, and other property details may vary, and some information may be incomplete until final verification from developers. As our platform is continuously evolving, we recommend consulting directly with developers or real estate professionals for the most current and comprehensive information.</p>
						<p>By using this platform, you acknowledge that Moovmynt is an informational resource, and any reliance on the provided data is at your own discretion.</p>
						</div>

					</div>

				</div>
				</div>
			</>
        }
}

export default WhyChooseUs