import React, { Component } from 'react';
import {Link, useParams} from 'react-router-dom';
import Comments from './comments';
import Sidebar from './sidebar';
import GetNesDetails from "../section-components/custom-components/GetNewsDetails";
const BlogDetails = () => {
	const { id } = useParams();

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
		<div className="ltn__page-details-area ltn__blog-details-area mb-120">
			<div className="container">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<GetNesDetails id={id}/>

				</div>
				<div className="col-lg-2"></div>

			</div>
			</div>
		</div>
    )

}

export default BlogDetails;
