import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class PortfolioDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__page-details-area ltn__portfolio-details-area mb-105">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="ltn__page-details-inner ltn__portfolio-details-inner">
                <p>
                  <h5>
                    The following definitions apply to these General Terms of
                    Service:
                  </h5>
                  “Applicable Governing Law” means the laws of the Province of
                  British Columbia and the federal laws of Canada applicable
                  therein. “Applicable Laws” means all applicable local,
                  municipal, provincial, territorial, national, and
                  international laws, rules, regulations, and guidelines.
                  “Content” refers to any ad, photo, video, message, document,
                  description, data, rating, review, comment, or other
                  information provided on the Moovmynt website or through its
                  Services. “Copyright Act” means the Copyright Act, RSC., 1985,
                  c. C-42.
                  <br /> <br />
                  “Exclusive Jurisdiction” means the courts located in the
                  Province of British Columbia. “Feedback” means any
                  suggestions, comments, or input provided regarding Moovmynt's
                  Site or Services. <br /> <br />
                  “Housing and Human Rights Laws” refers to any law prohibiting
                  discrimination or bias based on race, color, religion, sex,
                  physical or mental disability, sexual orientation, marital
                  status, ancestry, source of income, or other protected
                  criteria. “Listing Service” refers to the Site's tool for
                  listing pre-sale properties. “Moovmynt,” “We,” “Us,” or “Our”
                  refers to the Moovmynt platform and its associated entities.
                  <br /> <br />
                  “Services” means any tools, insights, analyses, or features
                  provided by Moovmynt, including MoovAI. “Site” refers to the
                  Moovmynt website and any related applications or platforms.
                  “Terms” collectively refers to these General Terms of Service,
                  the Privacy Policy, and any other terms provided on the Site.
                  <br /> <br /> “Third-Party Content” refers to content provided
                  by external sources or links to external websites. “User,”
                  “You,” or “Your” refers to any individual or organization
                  accessing or using Moovmynt’s Site or Services. “Your Content”
                  refers to any content, such as reviews, ratings, or other
                  submissions, uploaded or shared by You on the Site.
                </p>
                <p>
                  <h5>Binding Agreement and Privacy Policy:</h5>
                  By accessing or using the Site or Services, You agree to these
                  Terms and represent that: You have reached the legal age of
                  majority in your jurisdiction. You comply with all applicable
                  laws. You will not use the Site or Services for unlawful or
                  prohibited purposes. Your use of the Site is governed by these
                  Terms and the Privacy Policy. If You disagree with any part of
                  these Terms, do not access or use the Site.
                </p>
                <p>
                  <h5>Nature of the Site and Services:</h5>Moovmynt provides
                  tools and insights to simplify the pre-sale property market
                  for buyers, realtors, and investors. Moovmynt does not: Act as
                  a real estate agent, broker, or financial advisor. Guarantee
                  property transactions, investments, or returns. Facilitate
                  payments or background checks on behalf of Users. You are
                  solely responsible for evaluating the information on the Site
                  and making independent decisions regarding properties or
                  investments.
                </p>
                <p>
                  <h5>Embedded content from other websites:</h5>Articles on this
                  site may include embedded content (e.g. videos, images,
                  articles, etc.). Embedded content from other websites behaves
                  in the exact same way as if the visitor has visited the other
                  website.
                  <br />
                  These websites may collect data about you, use cookies, embed
                  additional third-party tracking, and monitor your interaction
                  with that embedded content, including tracking your
                  interaction with the embedded content if you have an account
                  and are logged in to that website.
                </p>
                <p>
                  <h5>Changes to Terms and Services:</h5>
                  We may update these Terms at any time. Updated Terms will be
                  posted on the Site, and Your continued use of the Site
                  constitutes acceptance. Moovmynt may also modify, add, or
                  remove features from the Site or Services without prior
                  notice.
                </p>
                <p>
                  <h5>Registration and Accounts: </h5>To use certain Services,
                  You may need to create an account. By doing so, You agree to:
                  Provide accurate and up-to-date information. Maintain the
                  confidentiality of your account credentials. Accept
                  responsibility for all activity under your account. Moovmynt
                  reserves the right to suspend or terminate accounts for
                  violations of these Terms.
                </p>
                <p>
                  <h5>Proprietary Rights and Restrictions: </h5>All Content on
                  the Site is protected by copyright and other intellectual
                  property laws. You may not copy, distribute, or modify
                  Moovmynt’s proprietary materials without written consent.
                </p>

                <p>
                  By submitting content to Moovmynt, You grant Us a worldwide,
                  royalty-free license to use, display, and distribute Your
                  Content for the purposes of promoting and improving the
                  Services. You agree that Your Content will not: Include false
                  or misleading information. Violate any third-party rights or
                  laws. Contain offensive, defamatory, or harmful material.
                  Moovmynt reserves the right to edit or remove any User Content
                  at its discretion.
                </p>
                <p>
                  <h5>Third-Party Content and Integrations:</h5>
                  Moovmynt may include links to third-party websites or content.
                  We do not guarantee the accuracy, security, or reliability of
                  these external resources. Use third-party content at your own
                  risk.
                </p>
                <p>
                  <h5>Disclaimers and Limitation of Liability: </h5>
                  Moovmynt provides the Site and Services on an “as is” and “as
                  available” basis. We make no guarantees about the accuracy,
                  reliability, or completeness of the Content. Moovmynt is not
                  liable for any direct, indirect, incidental, or consequential
                  damages arising from Your use of the Site or Services. Users
                  are solely responsible for evaluating property or investment
                  opportunities.
                </p>
                <p>
                  <h5>User Representations and Responsibilities:</h5>
                  By using the Site, You represent and agree that: You will not
                  use the Site for unlawful purposes. You will not mislead,
                  defraud, or harm other Users. You will comply with all
                  applicable laws and regulations.
                </p>
                <p>
                  <h5>Indemnification:</h5>
                  You agree to indemnify and hold harmless Moovmynt, its
                  affiliates, and employees from any claims, damages, or losses
                  arising from: Your use of the Site or Services. Your Content
                  or conduct. Any violation of these Terms.
                </p>
                <p>
                  <h5>Future Features and Blockchain Integration:</h5>
                  Moovmynt is actively developing features such as blockchain
                  verification and real estate tokenization. These tools will
                  enhance transparency and accessibility but are not yet
                  available.
                </p>
                <p>
                  <h5>Termination</h5>
                  Moovmynt reserves the right to suspend or terminate Your
                  account for any violation of these Terms. You may discontinue
                  use of the Site and Services at any time.
                </p>
                <p>
                  <h5>Governing Law and Disputes</h5>
                  These Terms are governed by the laws of the Province of
                  British Columbia and the federal laws of Canada applicable
                  therein. Any disputes will be resolved in the courts of
                  British Columbia unless prohibited by law.
                </p>
                <p>
                  <h5>Contact Information: </h5>
                  For questions or concerns, please contact us at
                  info@moovmynt.com.
                </p>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioDetails;
