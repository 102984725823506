import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Link } from "react-router-dom";
import "leaflet/dist/leaflet.css";

const Location = () => {
  const [propertyDetailsList, setPropertyDetailsList] = useState([]);
  const center = [49.1936, -122.9609]; // Center of the map

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/properties/top-locations/"
        );
        const data = await response.json();
        const properties = data.map((property) => ({
          id: property.id,
          name: property.name,
          price: property.price,
          address: property.address,
          property_type: property.property_type,
          developer: property.developer,
          city: property.city,
          position: [property.latitude, property.longitude],
        }));
        setPropertyDetailsList(properties);
      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    };

    fetchData();
  }, []);

  // Helper to restrict panning in the map (Optional - Only if needed)
  const SetMapBounds = () => {
    const map = useMap();
    map.setMaxBounds([
      [-85, -180], // South-West corner
      [85, 180],   // North-East corner
    ]);
    return null;
  };

  return (
    <div className="m-5">
      <MapContainer
        center={center}
        zoom={11}
        style={{
          height: "600px",
          width: "100%",
          pointerEvents: "auto", // Ensure map is scrollable
        }}
        minZoom={2}
        maxZoom={18}
        worldCopyJump={false} // Disable wrapping behavior
        // scrollWheelZoom={false} 
      >
        <SetMapBounds />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          noWrap={true} // Prevent tile wrapping
        />
        {propertyDetailsList.map((property) => (
          <Marker key={property.id} position={property.position}>
            <Popup>
              <img
                src={"assets/img/others/10.png"}
                alt={property.name}
                style={{
                  width: "100%",
                  height: "50%",
                  marginTop: 15,
                  marginBottom: 10,
                  marginLeft: 1,
                }}
              />
              <div style={{ fontFamily: "Arial, sans-serif", fontSize: "12" }}>
                <p>
                  {property.name} ( {property.property_type})
                </p>
                <p>Developer:       {(property.developer && property.developer !== "Unknown Developer") ? property.developer : "TBD"}
                </p>
                <p>
                  Location: {property.address}, {property.city}
                </p>
                <p>
                  Price: {property.price === 0 ? "TBD" : `$${property.price.toLocaleString()}`}
                </p>
                <p>
                  View Details:{" "}
                  <Link to={`/product-details/${property.id}`}>Click Here</Link>
                </p>

              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default Location;
