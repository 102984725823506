import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const FetchBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch blog data from the API
    axios.get('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/home/all-news-api/')
      .then(response => {
        console.log('Data fetching from news at custom component:', response.data.results);
        setBlogs(response.data.results);  // Set the results into the state
      })
      .catch(error => {
        console.error("Error fetching blogs:", error);
      });
  }, []);

  const publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div className="ltn__blog-list-wrap">
      {blogs.map((blog, index) => (
        <div className="ltn__blog-item ltn__blog-item-5 go-top" key={index}>
          <div className="ltn__blog-img">
            <Link to={`/blog-details/${blog.slug}`}>
              <img
                src={blog.image}
                alt={blog.news_title}
              />
            </Link>
          </div>
          <div className="ltn__blog-brief">

            <h3 className="ltn__blog-title">
              <Link to={`/blog-details/${blog.slug}`}>{blog.news_title}</Link>
            </h3>
            <div className="ltn__blog-meta">
              <ul>
                <li>
                  <Link to={`/blog-details/${blog.slug}`}>
                    <i className="far fa-eye" /> {"0"} Views
                  </Link>
                </li>
                <li>
                  <Link to={`/blog-details/${blog.slug}`}>
                    <i className="far fa-comments" /> {"0"} Comments
                  </Link>
                </li>
                <li className="ltn__blog-date">
                  <i className="far fa-calendar-alt" />
                  {new Date(blog.created_on).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </li>
              </ul>
            </div>
            <p>{blog.details || blog.details.slice(0, 150) + '...'}</p>
            <div className="ltn__blog-meta-btn">
              <div className="ltn__blog-meta">
                <ul>
                  <li className="ltn__blog-author">

                      <img src={publicUrl + "assets/img/blog/author.jpg"} alt="author" /> By: {blog.created_by || "Unknown"}

                  </li>
                </ul>
              </div>
              <div className="ltn__blog-btn">
                <Link to={`/blog-details/${blog.slug}`}>
                  <i className="fas fa-arrow-right" /> Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FetchBlogs;
