import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';


import About from './components/about';
import Service from './components/service';
import ServiceDetails from './components/service-details';
import Portfolio from './components/portfolio';
import PortfolioV2 from './components/portfolio-v2';
import PortfolioDetails from './components/portfolio-details';
import Team from './components/team';
import TeamDetails from './components/team-details';
import Faq from './components/faq';
import ComingSoon from './components/coming-soon';
import Error  from './components/404';
import Location  from './components/location';

import Shop from './components/shop';

import ProdductDetails from './components/product-details';
import ShopLeftSidebar from './components/shop-left-sidebar';
import ShopRightSidebar from './components/shop-right-sidebar';

import BlogGrid from './components/blog-grid';
import BlogLeftSidebar from './components/blog-left-sidebar';
import BlogRightSidebar from './components/blog-right-sidebar';
import Blog from './components/blog';

import BlogDetails from './components/blog-details';
import Contact from './components/contact';
import Checkout from './components/checkout';
import MyAccount from './components/my-account';
import Login from './components/login';
import Register from './components/register';
import Disclaimer from './components/history';



class Root extends Component {
    render() {
        return(
                <Router basename="/">
	                <div>
	                <Switch>
	                    <Route exact path="/" component={HomeV1} />


                        <Route path="/about" component={About} />
                        <Route path="/service" component={Service} />
                        <Route path="/service-details" component={ ServiceDetails } />
                        <Route path="/portfolio" component={ Portfolio } />
                        <Route path="/portfolio-v2" component={ PortfolioV2 } />
                        <Route path="/privacy-policy" component={ PortfolioDetails } />
                        <Route path="/team" component={ Team } />
                        <Route path="/team-details/:id" component={ TeamDetails } />
                        <Route path="/faq" component={ Faq } />
                        <Route path="/coming-soon" component={ ComingSoon } />
                        <Route path="/404" component={ Error } />
                        <Route path="/location" component={ Location } />
                        <Route path="/shop" component={ Shop } />


                        <Route path="/shop-left-sidebar" component={ ShopLeftSidebar } />


                        <Route path="/listings" component={ ShopLeftSidebar } />

                        <Route path="/shop-right-sidebar" component={ ShopRightSidebar } />

                        <Route path="/product-details/:id" component={ ProdductDetails } />
                        {/* blog */}
                        <Route path="/blog-grid" component={ BlogGrid } />
                        <Route path="/all-news" component={ BlogLeftSidebar } />
                        <Route path="/blog-right-sidebar" component={ BlogRightSidebar } />
                        <Route path="/blog" component={ Blog } />


                        <Route path="/blog-details/:id" component={ BlogDetails } />
                        <Route path="/contact" component={ Contact } />

                        <Route path="/checkout" component={ Checkout } />
                        <Route path="/my-account" component={ MyAccount } />
                        <Route path="/login" component={ Login } />
                        <Route path="/register" component={ Register } />
                        <Route path="/disclaimer" component={ Disclaimer } />



	                </Switch>
	                </div>
                </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
