import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';
import FetchBlogs from "./custom-components";
class BlogList extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    return (
		<div className="ltn__blog-area mb-120">
			<div className="container">
			<div className="row">
				<div className="col-lg-8 order-lg-2">
				<FetchBlogs/>
				<div className="row">
					<div className="col-lg-12">
					<div className="ltn__pagination-area text-center">
						<div className="ltn__pagination">
						<ul>
							<li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
							<li><Link to="#">1</Link></li>

							<li><Link to="#"><i className="fas fa-angle-double-right" /></Link></li>
						</ul>
						</div>
					</div>
					</div>
				</div>
				</div>
				<Sidebar/>
			</div>
			</div>
		</div>
    )
  }
}

export default BlogList;
