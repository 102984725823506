import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TestimonialSection = () => {
    let publicUrl = process.env.PUBLIC_URL; // Public URL for asset paths
    const [testimonials, setTestimonials] = useState([]); // State to hold API data

    // Fetch API data on component mount
    useEffect(() => {
        axios.get('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/home/client-feedback-api/')
            .then(response => {
                console.log('Data fetching from client feedback', response.data.results);
                setTestimonials(response.data.results); // Assuming the API returns an array of testimonials
            })
            .catch(error => {
                console.error("Error fetching testimonials:", error);
            });
    }, []); // Empty array to run only once when the component mounts

    return (
        <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
            {testimonials.map((testimonial, index) => (
                <div className="col-lg-4" key={index}>
                    <div className="ltn__testimonial-item ltn__testimonial-item-7">
                        <div className="ltn__testimoni-info">
                            <p><i className="flaticon-left-quote-1" />
                                {testimonial.feedback} {/* Dynamic feedback text */}
                            </p>
                            <div className="ltn__testimoni-info-inner">
                                <div className="ltn__testimoni-img">
                                    <img
                                        src={testimonial.image || publicUrl + "assets/img/testimonial/default.jpg"}
                                        alt={testimonial.client_name || "Testimonial"}
                                    /> {/* Dynamic image or fallback */}
                                </div>
                                <div className="ltn__testimoni-name-designation">
                                    <h5>{testimonial.client_name || "Anonymous"}</h5> {/* Dynamic client name */}
                                    <label>{testimonial.client_position || "Client"}</label> {/* Dynamic client position */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TestimonialSection;
