import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import GetTeamMemberDetails from './custom-components/GetTeamMemberDetails';

const TeamDetails = () => {
    // Get the team member ID from the URL parameters
    const { id: teamMemberId } = useParams(); // Destructure id directly

    console.log('Team Member ID:', teamMemberId);

    return (
        <div className="ltn__team-details-area mb-120">
            <div className="container">
                <GetTeamMemberDetails member_id={teamMemberId} />
            </div>
        </div>
    );
}

export default TeamDetails;
