import React, {Component} from 'react';
import axios from 'axios';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',

            phone_number: '',
            message: '',
            agree: false,
            formMessage: '',
            isError: false,
        };
    }

    // Handle form input changes
    handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;
        this.setState({
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Handle form submission
    handleSubmit = (e) => {
        e.preventDefault();

        const {name, email, phone_number, message} = this.state;


        // Prepare the form data
        const formData = {
            name,
            email,

            phone_number,
            message,

        };
        console.log('Data sending via contact page:', formData);
        // Send POST request using axios
        axios
            .post('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/home/contact/', formData)
            .then((response) => {
                console.log('Line no 47 at contact form:', response);
                this.setState({
                    formMessage: 'Your message was sent successfully!',
                    isError: false,
                    // Clear the form
                    name: '',
                    email: '',

                    phone_number: '',
                    message: '',
                    agree: false,
                });
            })
            .catch((error) => {
                this.setState({
                    formMessage:
                        error.response?.data || 'Oops! An error occurred and your message could not be sent.',
                    isError: true,
                });
            });
    };

    render() {
        const {name, email, phone_number, message, agree, formMessage, isError} = this.state;

        return (
            <div className="ltn__contact-message-area mb-120 mb--100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ltn__form-box contact-form-box box-shadow white-bg">
                                <h5 className="title-2">
                                    We’re always happy to help! Please don’t hesitate to get back to us—we’ll get right
                                    on it as soon as possible. Thank you very much.
                                </h5>

                                <form id="contact-form" onSubmit={this.handleSubmit}>
                                    {formMessage && (
                                        <p className={`form-messege mb-0 mt-20 ${isError ? 'error' : 'success'}`}>
                                            {formMessage}
                                        </p>
                                    )}
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="input-item input-item-name ltn__custom-icon">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter your name"
                                                    value={name}
                                                    onChange={this.handleInputChange} required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="input-item input-item-email ltn__custom-icon">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter email address"
                                                    value={email}
                                                    onChange={this.handleInputChange} required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="input-item input-item-phone ltn__custom-icon">
                                                <input
                                                    type="text"
                                                    name="phone_number"
                                                    placeholder="Enter phone number"
                                                    value={phone_number}
                                                    onChange={this.handleInputChange} required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-item input-item-textarea ltn__custom-icon">
                    <textarea
                        name="message"
                        placeholder="Enter message"
                        value={message}
                        onChange={this.handleInputChange} required
                    />
                                    </div>
                                    <p>
                                        <label className="input-info-save mb-0">
                                            <input
                                                type="checkbox"
                                                name="agree"
                                                checked={agree}
                                                onChange={this.handleInputChange} required
                                            />{' '}
                                            Save my name, email, and website in this browser for the next time I
                                            comment.
                                        </label>
                                    </p>
                                    <div className="btn-wrapper mt-0">
                                        <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">
                                            send message
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactForm;
