import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import GetTeam from './custom-components/GetAllTeam.js';
class TeamV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__team-area pt-110--- pb-90">
				<div className="container">
					<GetTeam/>
				</div>
			</div>
        }
}

export default TeamV2