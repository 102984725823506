import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Add axios to handle API requests

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            errors: {},
            message: '', // State to store success or error message
            messageType: '', // To differentiate success or error message (success or error)
        };
    }

    // Handle input changes
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    };

    // Validate the form fields
    validateForm = () => {
        const { firstName, lastName, email, password, confirmPassword } = this.state;
        const errors = {};

        if (!firstName) {
            errors.firstName = 'First name is required';
        }
        if (!lastName) {
            errors.lastName = 'Last name is required';
        }
        if (!email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid';
        }
        if (!password) {
            errors.password = 'Password is required';
        }
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };

    // Handle form submission
    handleSubmit = (e) => {
        e.preventDefault();

        // Validate form before submitting
        if (this.validateForm()) {
            const { firstName, lastName, email, password } = this.state;

            // Post request to sign up endpoint
            axios
                .post('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/authentication/register/', {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    password: password,
                })
                .then((response) => {
                    // On success, set success message
                    console.log('Line no 73 in success mode is:',response);
                    this.setState({
                        message: 'Account created successfully! Please check your email for activation.',
                        messageType: 'success',
                        errors: {}, // Clear errors
                    });
                })
                .catch((error) => {
                    console.log('Line no 81 in error mode is:',error);
                    const errorMsg = error.response?.data?.detail || 'Error in creating account';
                    this.setState({
                        message: errorMsg,
                        messageType: 'error',
                    });
                });
        }
    };

    render() {
        const { firstName, lastName, email, password, confirmPassword, errors, message, messageType } = this.state;

        return (
            <div className="ltn__login-area pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area text-center">
                                <h1 className="section-title">Register <br />Your Account</h1>
                                {/* Display success or error message here */}
                                {message && (
                                    <div className={messageType === 'success' ? ' alert alert-success' : 'alert alert-danger'}>
                                        {message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="account-login-inner">
                                <form className="ltn__form-box contact-form-box" onSubmit={this.handleSubmit}>
                                    <input
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                    {errors.firstName && <span className="error">{errors.firstName}</span>}

                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                    {errors.lastName && <span className="error">{errors.lastName}</span>}

                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                    {errors.email && <span className="error">{errors.email}</span>}

                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password*"
                                        value={password}
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                    {errors.password && <span className="error">{errors.password}</span>}

                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Confirm Password*"
                                        value={confirmPassword}
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                    {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}

                                    <label className="checkbox-inline">
                                        <input type="checkbox" required /> &nbsp;
                                        I consent to Herboil processing my personal data in accordance with the privacy policy.
                                    </label>
                                    <label className="checkbox-inline">
                                        <input type="checkbox" required /> &nbsp;
                                        By clicking "create account", I consent to the privacy policy.
                                    </label>
                                    <div className="btn-wrapper">
                                        <button className="theme-btn-1 btn reverse-color btn-block" type="submit">
                                            CREATE ACCOUNT
                                        </button>
                                    </div>
                                </form>
                                <div className="by-agree text-center">
                                    <p>By creating an account, you agree to our:</p>
                                    <p><a href="#">TERMS OF CONDITIONS  &nbsp; &nbsp; | &nbsp; &nbsp;  PRIVACY POLICY</a></p>
                                    <div className="go-to-btn mt-50 go-top">
                                        <Link to="/login">ALREADY HAVE AN ACCOUNT ?</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
