import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const GetTeamMemberDetails = ({ member_id }) => {
  const [teamMember, setTeamMember] = useState({});
  const publicUrl = process.env.PUBLIC_URL + '/';

  useEffect(() => {
    // Fetch the team members from your API
    axios.get(`https://moovmynt-backend-7a58dd9e9029.herokuapp.com/home/team/${member_id}`)  // Replace with your actual API endpoint
      .then(response => {
        console.log('Team member details are:', response);
        setTeamMember(response.data);
      })
      .catch(error => {
        console.error('Error fetching the team members:', error);
      });
  }, [member_id]);

  console.log('teamMember set is:', teamMember);

  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="ltn__team-details-member-info text-center mb-40">
          <div className="team-details-img">
            <img src={teamMember.image} alt="Team Member Image" />
          </div>
          <h2>{teamMember.name}</h2> {/* Change this according to your API response */}
          <h6 className="text-uppercase ltn__secondary-color">{teamMember.position}</h6>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="ltn__team-details-member-info-details">
          <p>{teamMember.details}</p>
          <div className="row">
            <div className="col-lg-6">
              <div className="ltn__team-details-member-about">
                <ul>
                  <li><strong>Positions:</strong> {teamMember.position}</li> {/* Assuming these are available in your API response */}
                  <li><strong>Experience:</strong> {teamMember.experience}</li>

                </ul>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="ltn__form-box contact-form-box box-shadow white-bg mt-50">
          <h4 className="title-2">Contact for any Inquiry</h4>
          <form id="contact-form" action="mail.php" method="post">
            <div className="row">
              <div className="col-md-6">
                <div className="input-item input-item-name ltn__custom-icon">
                  <input type="text" name="name" placeholder="Enter your name" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-item input-item-email ltn__custom-icon">
                  <input type="email" name="email" placeholder="Enter email address" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-item">
                  <select className="nice-select">
                    <option>Select Service Type</option>
                    <option>Property Management</option>
                    <option>Mortgage Service</option>
                    <option>Consulting Service</option>
                    <option>Home Buying</option>
                    <option>Home Selling</option>
                    <option>Escrow Services</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-item input-item-phone ltn__custom-icon">
                  <input type="text" name="phone" placeholder="Enter phone number" />
                </div>
              </div>
            </div>
            <div className="input-item input-item-textarea ltn__custom-icon">
              <textarea name="message" placeholder="Enter message" defaultValue={""} />
            </div>
            <p>
              <label className="input-info-save mb-0">
                <input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.
              </label>
            </p>
            <div className="btn-wrapper mt-0">
              <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Get a free service</button>
            </div>
            <p className="form-messege mb-0 mt-20" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetTeamMemberDetails;