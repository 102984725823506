import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const GetTeam = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    // Fetch the team members from your API
    axios.get('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/home/team/')  // Replace with your actual API endpoint
      .then(response => {

        setTeamMembers(response.data.results);
      })
      .catch(error => {
        console.error('Error fetching the team members:', error);
      });
  }, []);
  console.log('teamMember seted is:',teamMembers)

  return (
    <div className="row justify-content-center go-top">

      {teamMembers.map((member, index) => (
        <div className="col-lg-3 col-sm-6" key={index}>
          <div className="ltn__team-item ltn__team-item-3---">
            <div className="team-img">
              <Link to={`/team-details/${member.id}`}>
              <img
                src={member.image || 'assets/img/team/default.jpg'}  // Default image if no image is provided
                alt={member.name}
              />
              </Link>
            </div>
            <div className="team-info">
              <h4><Link to={`/team-details/${member.id}`}>{member.name}</Link></h4>
              <h6 className="ltn__secondary-color">{member.position}</h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GetTeam;
