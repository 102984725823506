import React from 'react';
import Navbar from './global-components/navbar';
import ShopLeftSidebar from './shop-components/shop-left-sidebar';
import Footer from './global-components/footer';
import Location from './section-components/location';

const ShopLeftSidebarPage = () => {
    return <div>
        <Navbar />
        <Location  />
        <ShopLeftSidebar />
        <Footer />
    </div>
}

export default ShopLeftSidebarPage

