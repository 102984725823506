import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';  // To make HTTP requests

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            message: '',  // To store success or error messages
            error: ''     // To store specific error messages
        };
    }

    // Handle form input changes
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    // Handle form submission
    handleSubmit = async (e) => {
        e.preventDefault();

        const { email, password } = this.state;

        try {
            // Make a POST request to your Django API
            const response = await axios.post('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/authentication/login/', { email, password });

            console.log('Response in Login view in try ',response);
            this.setState({ message: response.data.message, error: '' });

        } catch (error) {
            // If there is an error (e.g., invalid credentials or account not activated)
            console.log('Response in Login view in catch view',error);
            if (error.response && error.response.data) {
                this.setState({ error: error.response.data.error || error.response.data.message, message: '' });
            } else {
                this.setState({ error: 'An unexpected error occurred.', message: '' });
            }
        }
    }

    render() {
        const { email, password, message, error } = this.state;

        return (
            <div>
                <div className="ltn__login-area pb-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h1 className="section-title">Sign In <br />To Your Account</h1>

                                    {/* Display success message */}
                                    {message && <div className="alert alert-success">{message}</div>}

                                    {/* Display error message */}
                                    {error && <div className="alert alert-danger">{error}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="account-login-inner">
                                    <form className="ltn__form-box contact-form-box" onSubmit={this.handleSubmit}>
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email*"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Password*"
                                            value={password}
                                            onChange={this.handleChange}
                                        />
                                        <div className="btn-wrapper mt-0">
                                            <button className="theme-btn-1 btn btn-block" type="submit">SIGN IN</button>
                                        </div>
                                        <div className="go-to-btn mt-20">
                                            <a href="#" title="Forgot Password?" data-bs-toggle="modal" data-bs-target="#ltn_forget_password_modal">
                                                <small>FORGOTTEN YOUR PASSWORD?</small>
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="account-create text-center pt-50">
                                    <h4>DON'T HAVE AN ACCOUNT?</h4>
                                    <p>Add items to your wishlist, get personalized recommendations, check out more quickly, track your orders, register</p>
                                    <div className="btn-wrapper go-top">
                                        <Link to="/register" className="theme-btn-1 btn black-btn">CREATE ACCOUNT</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
