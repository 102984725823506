import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import TeamDetails from './section-components/team-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import {useParams} from "react-router-dom";

const Team_Details = () => {
    const { id } = useParams();
    return <div>
        <Navbar />
        <PageHeader headertitle=" Details" />
        <TeamDetails id={id} />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Team_Details

