import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-115 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-5 align-self-center">
					<div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
						<img src={publicUrl+"assets/img/service/11.jpg"} alt="Image" />
					</div>
					</div>
					<div className="col-lg-7 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
						<h1 className="section-title">A New Era in Real Estate<span>.</span></h1>
						<p>Step into the future of home buying with Moovmynt. Our platform, powered by <b>MoovAI</b>, redefines how homebuyers and realtors navigate the pre-sale market. With AI-driven insights and advanced tools, we simplify the process while providing the clarity needed for smarter, data-driven decisions.</p>
						</div>
						<div className="about-us-info-wrap-inner about-us-info-devide---">
							<p>Explore our robust features, including the Pre-Sale Master List, advanced ROI tracking, and real-time data on commissions and payouts. <b>MoovAI</b> ensures your journey is efficient, informed, and aligned with your goals, giving you the edge in every transaction.</p>
						</div>
						<div className="btn-wrapper animated">
						<Link to="/contact" className="theme-btn-1 btn btn-effect-1 text-uppercase">Contact Us</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>	
        }
}

export default AboutV5