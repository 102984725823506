import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const FetchTopFiveBlog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch blog data from the API
    axios.get('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/home/all-news-api/')
      .then(response => {
        console.log('Data fetching from news at custom component:', response.data.results);
        // Set the top 5 results into the state
        setBlogs(response.data.results.slice(0, 5));
      })
      .catch(error => {
        console.error("Error fetching blogs:", error);
      });
  }, []);

  const publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <ul>
      {blogs.map((blog, index) => (
        <li key={index}>
          <div className="popular-post-widget-item clearfix">
            <div className="popular-post-widget-img">
              <Link to={`/blog-details/${blog.slug}`}>
                <img src={blog.image || publicUrl + "assets/img/team/placeholder.jpg"} alt={blog.news_title} />
              </Link>
            </div>
            <div className="popular-post-widget-brief">
              <h6>
                <Link to={`/blog-details/${blog.slug}`}>{blog.news_title}</Link>
              </h6>
              <div className="ltn__blog-meta">
                <ul>
                  <li className="ltn__blog-date">
                    <Link to="#">
                      <i className="far fa-calendar-alt" />
                      {new Date(blog.created_on).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FetchTopFiveBlog;
