import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Banner extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return    <div className="ltn__slider-area ltn__slider-3  section-bg-1 go-top">
				  <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
				    {/* ltn__slide-item */}
				    <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3">
				      <div className="ltn__slide-item-inner">
				        <div className="container">
				          <div className="row">
				            <div className="col-lg-12 align-self-center">
				              <div className="slide-item-info">
				                <div className="slide-item-info-inner ltn__slide-animation">
				                  <div className="slide-video mb-50 d-none">
				                    <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/KVAHW6qDpfY" data-rel="lightcase:myCollection">
				                      <i className="fa fa-play" />
				                    </a>
				                  </div>

				                  <h1 className="slide-title animated ">Discover Tomorrow's  <br /> Homes Today</h1>
				                  <div className="slide-brief animated">
									  <p>Welcome to Moovmynt, where we’re transforming the real estate experience. Our platform features <b>MoovAI</b>—an advanced AI-powered tool designed to provide in-depth analysis, ROI calculations, and client management. While <b>MoovAI</b> is equipped to evolve with future blockchain integration, it currently delivers powerful insights to help you make informed, secure decisions. Whether you’re buying, selling, or investing, Moovmynt’s cutting-edge tools simplify the pre-sale property market, offering the resources you need to succeed.</p>
				                  </div>
				                  <div className="btn-wrapper animated ">
				                    	<Link to="/contact" className="theme-btn-1 btn btn-effect-1 go-top">Make An Enquiry</Link>
				                    <a className="ltn__video-play-btn bg-white" href="https://www.youtube.com/embed/KVAHW6qDpfY" data-rel="lightcase">
				                      <i className="icon-play  ltn__secondary-color" />
				                    </a>
				                  </div>
				                </div>
				              </div>



				              <div className="slide-item-img">
				                <img src={publicUrl+"assets/img/slider/21.png"} alt="#" />
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				    {/* ltn__slide-item */}
				    <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal ltn__slide-item-3">
				      <div className="ltn__slide-item-inner  text-right text-end">
				        <div className="container">
				          <div className="row">
				            <div className="col-lg-12 align-self-center">
				              <div className="slide-item-info">
				                <div className="slide-item-info-inner ltn__slide-animation">
				                  <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> About Us</h6>
				                  <h1 className="slide-title animated ">Your Trusted Partner in Finding the Perfect Home</h1>
				                  <div className="slide-brief animated">
									  <p>At Moovmynt, we’re reshaping the future of real estate with innovative tools that enhance your entire journey. <b>MoovAI</b>, our AI-powered analysis platform, goes beyond just CRM capabilities by offering advanced property insights, ROI calculations, and client management features. Although future blockchain and tokenization integration are on the horizon, <b>MoovAI</b> already empowers homebuyers, realtors, and investors with data-driven solutions today. Trust Moovmynt to provide the tools and insights you need for confident decision-making in the pre-sale property market.</p>
				                  </div>
				                  <div className="btn-wrapper animated">
				                    <Link to="/service" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
				                    <Link to="/about" className="btn btn-transparent btn-effect-3">LEARN MORE</Link>
				                  </div>
				                </div>
				              </div>
				              <div className="slide-item-img slide-img-left">
				                <img src={publicUrl+"assets/img/slider/21.png"} alt="#" />
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
				    {/*  */}
				  </div>
			</div>
        }
}

export default Banner