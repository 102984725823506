import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV5 extends Component {

    render() {


        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h2 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Core
                                Services</h2>

                        </div>
                    </div>
                </div>
                <div className="row  justify-content-center">
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">

                                <span> <img src={publicUrl+"assets/img/icons/icon-img/pre-sale-master-list-Photoroom.png"} style={{ height: '100px', width: '100px' }} /></span>

                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Pre-Sale Master List</Link></h3>
                                <p>Unlock comprehensive insights with Moovmynt’s Pre-Sale Master List. Whether you’re a
                                    homebuyer looking to get ahead in the market or a realtor identifying prime
                                    opportunities, our database provides detailed, up-to-date information on upcoming
                                    properties. Powered by MoovAI, you can confidently make informed decisions backed by
                                    the latest data and trends.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                             <span> <img src={publicUrl+"assets/img/icons/icon-img/deposit-matrix-structure-Photoroom.png"} style={{ height: '100px', width: '100px' }} /></span>

                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Deposit Structure Metrics</Link></h3>
                                <p>Simplify financial planning with Moovmynt’s Deposit Structure Metrics. Our tailored
                                    insights provide clarity on deposit requirements and cash flow strategies, helping
                                    you optimize your investments. With MoovAI’s advanced analysis, you can navigate
                                    each transaction with precision, ensuring smarter financial decisions whether you're
                                    purchasing your first home or managing a portfolio.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                               <span> <img src={publicUrl+"assets/img/icons/icon-img/comission-and-bonus-Photoroom.png"} style={{ height: '100px', width: '100px' }} /></span>

                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Commissions & Bonuses</Link></h3>
                                <p>Maximize your profitability with Moovmynt’s Commissions & Bonuses insights. Our
                                    platform, enhanced by MoovAI, tracks commission structures and bonus schedules,
                                    ensuring transparency and timely payouts. Whether you’re a realtor driving sales or
                                    an investor managing partnerships, leverage our analysis to ensure fair compensation
                                    and plan for optimal returns.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                          <span> <img src={publicUrl+"assets/img/icons/icon-img/block-chain-verification-Photoroom.png"} style={{ height: '100px', width: '100px' }} /></span>

                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Blockchain Verification</Link></h3>
                                <p>Secure your transactions with future-proof technology. MoovAI will harness the power
                                    of blockchain to ensure property data and ownership records are tamper-proof and
                                    transparent. As the platform evolves, you’ll benefit from immutable verification
                                    that boosts trust and streamlines real estate deals. Don’t miss out on the future of
                                    secure, trusted real estate transactions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">

                                 <span><i className="flaticon-mortgage"/></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Real Estate Tokenization</Link></h3>
                                <p>Unlock new investment opportunities with real estate tokenization through MoovAI. In
                                    the near future, Moovmynt will enable fractional ownership, allowing users to invest
                                    in properties at any scale. With blockchain-backed tokenization, you’ll access a
                                    global pool of investors and diversify your portfolio with ease. Stay ahead of the
                                    curve and be part of the movement that’s reshaping how we invest in real estate.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">

                              <span> <img src={publicUrl+"assets/img/icons/icon-img/defi-and-apps-solution-Photoroom.png"} style={{ height: '100px', width: '100px' }} /></span>

                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">DeFi and Apps Solutions</Link></h3>
                                <p>As MoovAI continues to grow, we’ll integrate DeFi and dApps, opening up a new world
                                    of decentralized finance in real estate. Imagine earning, lending, or trading real
                                    estate assets with peer-to-peer solutions, all within a secure blockchain ecosystem.
                                    This is the future of real estate, and with MoovAI leading the way, you won’t want
                                    to miss the opportunities it offers.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                                <span><i className="flaticon-house-1"/></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Home Buying</Link></h3>
                                <p>Discover your perfect home with Moovmynt. Our platform is tailored to connect
                                    homebuyers with properties that fit their unique needs. From browsing comprehensive
                                    listings to accessing insights powered by MoovAI, we help make your home search
                                    efficient and tailored to your preferences.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                                  <span> <img src={publicUrl+"assets/img/icons/icon-img/investment-ro-calculator-Photoroom.png"} style={{ height: '100px', width: '100px' }} /></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Investment & ROI Solutions</Link></h3>
                                <p>Achieve smarter investment outcomes with Moovmynt’s ROI-focused services. Our
                                    analysis tools and insights ensure you stay informed about potential returns,
                                    allowing you to make confident, data-backed investment decisions. With MoovAI’s
                                    expertise, managing and optimizing your real estate investments has never been more
                                    straightforward.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                            <div className="ltn__feature-icon">
                                <span><i className="flaticon-house-3"/></span>
                            </div>
                            <div className="ltn__feature-info">
                                <h3><Link to="/service-details">Home Selling</Link></h3>
                                <p>Maximize your home’s value and visibility through Moovmynt’s targeted solutions. With
                                    our data-driven insights and strategic approach, we assist sellers in positioning
                                    their properties for optimal results. Let MoovAI’s advanced tools help you reach the
                                    right buyers and streamline the selling process.</p>
                            </div>
                        </div>
                    </div>


                    {/*another 3 properties*/}

                </div>
            </div>
        </div>
    }

}

export default ServiceV5